
.pg-login:before {
    filter: blur(5px);
}

.pg-login .google-login-btn {
    width: 100%;
    background-color: #ffffff;
    color: #444 !important;
    border: 1px solid #ced4da;
}

.pg-login .google-login-btn:hover {
    background-color: #f9f9f9 !important;
    color: #444 !important;
    border: 1px solid #ced4da !important;
}

.pg-login .google-login-btn:active {
    background-color: #f9f9f9 !important;
    color: #444 !important;
    border-color: #ced4da !important;
    box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5) !important;
}

.pg-login .google-login-btn:focus {
    box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}

.pg-login .facebook-login-btn {
    width: 100%;
    background: #4267b2;
}

.pg-login .email-login-btn {
    width: 100%;
}

.hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: .5;
}

.hr-text:before {
    content: '';
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
}

.hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 .5em;
    line-height: 1.5em;
    background-color: #fcfcfa;
}